import * as $ from 'jquery';
import mobile_menu from './mobile_menu.js';

(() => {

        function update_geometry() {
                $("#overlay").width($(window).width());
                $("#overlay").height($(window).height());
        }

        $(document).ready(function () {
                update_geometry();
                mobile_menu({menu: $('#topnav'), position: 'right'});
        });
        $(window).resize(function () {
                update_geometry();
        });

})();